.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.containter {
  width: 80% !important;
  max-width: 90% !important;
  margin-left: auto;
  margin-right: auto;
}

table,
thead,
tbody,
tr,
td,
th {
  background-color: rgb(21, 32, 43) !important;
  color: white !important;
}

.logoSmall {
  width: 75px;
}

.titleSize {
  font-size: 50px;
  padding: 0px !important;
  margin: 0px !important;
}

.titleHeight {
  height: 50px !important;
  max-height: 50px !important;
}

thead {
  border-top: 1px solid white !important;
}

.swalSelect .swal2-select {
  background-color: rgb(21, 32, 43) !important;
  color: white !important;
}

.swal2-select>option {
  background-color: rgb(21, 32, 43) !important;
  color: white !important;
}

.formContainer {
  width: 400px !important;
  max-width: 90% !important;
}

.checkboxHover:hover {
  cursor: pointer;
}

.forma {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.loginBtn {
  background-color: #1DA1F2 !important;
  border-color: #1DA1F2 !important;
  color: white !important;
}

.loginBtn:hover {
  background-color: #47a2db !important;
  border-color: black !important;
  color: black;
}

a {
  color: white !important;
}


a:hover {
  text-decoration: none;
}

input {
  background-color: rgb(21, 32, 43) !important;
  border-color: #1DA1F2 !important;
  color: white !important;
}

.loginLogo {
  width: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(21, 32, 43) inset !important;
  -webkit-text-fill-color: white !important;
}

.underlineBtn:hover {
  text-decoration: underline !important;
}

select {
  background-color: rgb(21, 32, 43) !important;
  color: white !important;
  border-color: #1DA1F2 !important;
}

option:hover {
  background-color: #1DA1F2 !important;
  color: white !important;

}

.overflowClass {
  overflow: auto;
}

.addUserBtn {
  background-color: #1DA1F2 !important;
  border-color: #1DA1F2 !important;
  color: white !important;
}

.addUserBtn:hover {
  background-color: #47a9e6 !important;
  border-color: #47a9e6 !important;
}

@media only screen and (max-width: 700px) {
  .titleSize {
    display: none;
  }

  .titleHeight {
    margin-bottom: 20px;
  }
}

a{
  text-decoration: none !important;
}

.linkZaSap{
  text-decoration: underline !important;
  color: #1DA1F2 !important;
  font-weight: bold;
}

.sapLogo{
  height: 100px !important;
  width: auto;
  margin-right: auto !important;
}